import React, {useState} from 'react'
import GitCLIDropdown from "../GitCLIDropdown";
import DeployFromGitModal from "./DeployFromGitModal";
import DeployFromCLIModal from "./DeployFromCLIModal";

function GitCLIModal() {
  const [activeModal, setActiveModal] = useState("CLI");
  return (
    <div className="relative">
      {
        activeModal === "CLI" ?
          <DeployFromCLIModal/>
          :
          <DeployFromGitModal/>
      }
      <div className="flex flex-row items-center justify-between absolute top-6 right-6">
        <GitCLIDropdown setActiveModal={setActiveModal}/>
      </div>
    </div>
  )
}

export default GitCLIModal
