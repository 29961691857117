import { ButtonProps } from "@/types/buttons";
import { classNames } from "@/utils/general";

const OutlineButton = ({ click, children, width = 'auto', additionalClasses="" }: ButtonProps) => {
    const classes = `py-3 px-8 border text-sm transition ease-in duration-100 rounded-md bg-white hover:border-gray-400 hover:bg-gray-100 focus:ring-2 focus:ring-gray-200 ${additionalClasses}`;
    return (
        <button onClick={click} className={classNames(classes, width === 'full' ? 'w-full' : '')}>
            {children}
        </button>
    );
}

OutlineButton.defaultProps = {
    width: 'auto',
    click: () => {}
}

export default OutlineButton;