import { ButtonProps } from "@/types/buttons";
import { classNames } from "@/utils/general";

const BlackButton = ({ click, children, width = 'auto', additionalClasses="" }: ButtonProps) => {
    const classes = `rounded-md py-3 px-8 text-sm border transition ease-in duration-100 bg-black hover:bg-gray-800 focus:ring-2 focus:ring-gray-800 text-white ${additionalClasses}`;
    return (
        <button onClick={click} className={classNames(classes, width === 'full' ? 'w-full' : '')}>
            {children}
        </button>
    );
}

BlackButton.defaultProps = {
    width: 'auto',
    click: () => {}
}

export default BlackButton;