import {getUserOnce} from '@/services/user-store';
import {User} from '@/types/user';
import Image from 'next/image';
import {useEffect, useState} from 'react';

async function getUserProfile(id: string) {
  const user = await getUserOnce(id);
  const data = user.data() as User;
  if (data) {
    return data.profileUrl;
  }
  return undefined;
}

const ProfileIcon = (props: any) => {
  const id = props.id;
  const [profileUrl, setProfileUrl] = useState<any>(undefined);
  useEffect(() => {
    getUserProfile(id).then((profile) => {
      setProfileUrl(profile);
    });
  }, [id])
  let image = (<Image layout="fill" objectFit="cover" src="/default-profile.png" alt="Profile Photo"/>);
  if (profileUrl) {
    // eslint-disable-next-line @next/next/no-img-element
    image = (<img className="object-cover h-full" alt="Profile Photo" src={profileUrl}/>)
  }
  return (
    <>{image}</>
  );
}

export default ProfileIcon;
